import gql from "graphql-tag";
import * as VueApolloComposable from "@vue/apollo-composable";
import * as VueCompositionApi from "vue";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  Guid: any;
};

export type AddNotificationEmailInputType = {
  emailAddress?: Maybe<Scalars["String"]>;
};

export type AddProfileRoleInputType = {
  profileId?: Maybe<Scalars["ID"]>;
  roleId?: Maybe<Scalars["ID"]>;
};

export type AddRoleAssigneeInputType = {
  roleId?: Maybe<Scalars["ID"]>;
  organizationId?: Maybe<Scalars["ID"]>;
};

export type AddUserRoleInputType = {
  userId?: Maybe<Scalars["ID"]>;
  roleId?: Maybe<Scalars["ID"]>;
  organizationId?: Maybe<Scalars["ID"]>;
};

export type ApplicationType = {
  applicationId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  /** The roles assigned to the application */
  roles?: Maybe<Array<Maybe<RoleType>>>;
  /** The profiles assigned to the application */
  profiles?: Maybe<Array<Maybe<ProfileType>>>;
};

export type AssignUserRoleOnBehalfOfInputfType = {
  email: Scalars["String"];
  roleId: Scalars["Guid"];
  organizationId: Scalars["Guid"];
};

export type AuditLog = {
  /** Items in the current page */
  items?: Maybe<Array<Maybe<AuditLogEntryType>>>;
  /** Total number of items */
  total?: Maybe<Scalars["Int"]>;
};

export type AuditLogEntryDetailFromValueType = {
  valueId?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type AuditLogEntryDetailToValueType = {
  valueId?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type AuditLogEntryDetailType = {
  property?: Maybe<Scalars["String"]>;
  propertyType?: Maybe<Scalars["String"]>;
  fromValues?: Maybe<Array<Maybe<AuditLogEntryDetailFromValueType>>>;
  toValues?: Maybe<Array<Maybe<AuditLogEntryDetailToValueType>>>;
};

export type AuditLogEntryType = {
  entityId?: Maybe<Scalars["String"]>;
  entityType?: Maybe<Scalars["String"]>;
  entityName?: Maybe<Scalars["String"]>;
  action?: Maybe<Scalars["String"]>;
  updatedDate?: Maybe<Scalars["DateTimeOffset"]>;
  userId?: Maybe<Scalars["ID"]>;
  userName?: Maybe<Scalars["String"]>;
  user?: Maybe<UserType>;
  logEntryDetails?: Maybe<Array<Maybe<AuditLogEntryDetailType>>>;
};

export type ChangeUserPasswordInputType = {
  userId?: Maybe<Scalars["ID"]>;
  password?: Maybe<Scalars["String"]>;
};

export type CreateApplicationInputType = {
  name?: Maybe<Scalars["String"]>;
};

export type CreateOrganizationInputType = {
  name?: Maybe<Scalars["String"]>;
  cvr?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  brokerId?: Maybe<Scalars["String"]>;
  cooperationType?: Maybe<Scalars["String"]>;
  nearPension?: Maybe<Scalars["String"]>;
  roleIds?: Maybe<Array<Maybe<Scalars["Guid"]>>>;
};

export type CreateProfileInputType = {
  applicationId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  roleIds?: Maybe<Array<Maybe<Scalars["Guid"]>>>;
};

export type CreateRoleInputType = {
  applicationId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  attribute?: Maybe<Scalars["String"]>;
  assigneeOrganizationIds?: Maybe<Array<Maybe<Scalars["Guid"]>>>;
};

export type CreateUserInputType = {
  email?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  administrator?: Maybe<Scalars["Boolean"]>;
  firstname?: Maybe<Scalars["String"]>;
  lastname?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  roleIds?: Maybe<Array<Maybe<Scalars["Guid"]>>>;
};

export type DirectoryMutation = {
  createOrganization?: Maybe<OrganizationType>;
  updateOrganization?: Maybe<OrganizationType>;
  deleteOrganization?: Maybe<Scalars["String"]>;
  /** Create a new user */
  createUser?: Maybe<UserType>;
  /** Update user information */
  updateUser?: Maybe<UserType>;
  changeUserPassword?: Maybe<Scalars["String"]>;
  deleteUser?: Maybe<Scalars["String"]>;
  /** Add user role */
  addUserRole?: Maybe<Scalars["String"]>;
  removeUserRole?: Maybe<Scalars["String"]>;
  removeRoleApplication?: Maybe<Scalars["String"]>;
  updateUserLastLogin?: Maybe<Scalars["String"]>;
  assignUserRoleOnBehalfOf?: Maybe<Scalars["String"]>;
  /** Reject user registration */
  rejectUser?: Maybe<Scalars["String"]>;
  createApplication?: Maybe<ApplicationType>;
  updateApplication?: Maybe<ApplicationType>;
  deleteApplication?: Maybe<Scalars["String"]>;
  createRole?: Maybe<RoleType>;
  updateRole?: Maybe<RoleType>;
  deleteRole?: Maybe<Scalars["String"]>;
  addRoleAssignee?: Maybe<Scalars["String"]>;
  removeRoleAssignee?: Maybe<Scalars["String"]>;
  createProfile?: Maybe<ProfileType>;
  updateProfile?: Maybe<ProfileType>;
  deleteProfile?: Maybe<Scalars["String"]>;
  addProfileRole?: Maybe<Scalars["String"]>;
  removeProfileRole?: Maybe<Scalars["String"]>;
  updateSetting?: Maybe<SettingType>;
  addNotificationEmail?: Maybe<NotificationEmailType>;
  deleteNotificationEmail?: Maybe<Scalars["String"]>;
};

export type DirectoryMutationCreateOrganizationArgs = {
  input: CreateOrganizationInputType;
};

export type DirectoryMutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInputType;
};

export type DirectoryMutationDeleteOrganizationArgs = {
  organizationId: Scalars["ID"];
};

export type DirectoryMutationCreateUserArgs = {
  input: CreateUserInputType;
};

export type DirectoryMutationUpdateUserArgs = {
  input: UpdateUserInputType;
};

export type DirectoryMutationChangeUserPasswordArgs = {
  input: ChangeUserPasswordInputType;
};

export type DirectoryMutationDeleteUserArgs = {
  userId: Scalars["ID"];
};

export type DirectoryMutationAddUserRoleArgs = {
  input: AddUserRoleInputType;
};

export type DirectoryMutationRemoveUserRoleArgs = {
  input: RemoveUserRoleInputType;
};

export type DirectoryMutationRemoveRoleApplicationArgs = {
  input: RemoveRoleApplicationInputType;
};

export type DirectoryMutationUpdateUserLastLoginArgs = {
  userId: Scalars["ID"];
};

export type DirectoryMutationAssignUserRoleOnBehalfOfArgs = {
  input: AssignUserRoleOnBehalfOfInputfType;
};

export type DirectoryMutationRejectUserArgs = {
  userId: Scalars["ID"];
};

export type DirectoryMutationCreateApplicationArgs = {
  input: CreateApplicationInputType;
};

export type DirectoryMutationUpdateApplicationArgs = {
  input: UpdateApplicationInputType;
};

export type DirectoryMutationDeleteApplicationArgs = {
  applicationId: Scalars["ID"];
};

export type DirectoryMutationCreateRoleArgs = {
  input: CreateRoleInputType;
};

export type DirectoryMutationUpdateRoleArgs = {
  input: UpdateRoleInputType;
};

export type DirectoryMutationDeleteRoleArgs = {
  roleId: Scalars["ID"];
};

export type DirectoryMutationAddRoleAssigneeArgs = {
  input: AddRoleAssigneeInputType;
};

export type DirectoryMutationRemoveRoleAssigneeArgs = {
  roleId: Scalars["ID"];
  organizationId: Scalars["ID"];
};

export type DirectoryMutationCreateProfileArgs = {
  input: CreateProfileInputType;
};

export type DirectoryMutationUpdateProfileArgs = {
  input: UpdateProfileInputType;
};

export type DirectoryMutationDeleteProfileArgs = {
  profileId: Scalars["ID"];
};

export type DirectoryMutationAddProfileRoleArgs = {
  input: AddProfileRoleInputType;
};

export type DirectoryMutationRemoveProfileRoleArgs = {
  input: RemoveProfileRoleInputType;
};

export type DirectoryMutationUpdateSettingArgs = {
  input: UpdateSettingInputType;
};

export type DirectoryMutationAddNotificationEmailArgs = {
  input: AddNotificationEmailInputType;
};

export type DirectoryMutationDeleteNotificationEmailArgs = {
  emailId: Scalars["ID"];
};

export type DirectoryQuery = {
  userProfile?: Maybe<UserProfileType>;
  /** List of users */
  users?: Maybe<UsersType>;
  user?: Maybe<UserType>;
  /** Get user by email */
  userByEmail?: Maybe<UserType>;
  /** Get user by UUID */
  userByUUID?: Maybe<UserType>;
  userRoles?: Maybe<Array<Maybe<UserRoleAssignmentType>>>;
  /** List of users with pagination */
  usersPagination?: Maybe<User>;
  /** List of organizations */
  organizations?: Maybe<OrganizationsType>;
  /** Get organization by id */
  organization?: Maybe<OrganizationType>;
  organizationByCvr?: Maybe<OrganizationType>;
  /** List of applications */
  applications?: Maybe<Array<Maybe<ApplicationType>>>;
  /** Get application by id */
  application?: Maybe<ApplicationType>;
  /** List of roles */
  roles?: Maybe<Array<Maybe<RoleType>>>;
  role?: Maybe<RoleType>;
  /** List of role assignees */
  roleAssignees?: Maybe<Array<Maybe<RoleAssigneeType>>>;
  /** List of roles for user */
  rolesForUser?: Maybe<Array<Maybe<RoleAssigneeType>>>;
  profiles?: Maybe<Array<Maybe<ProfileType>>>;
  profile?: Maybe<ProfileType>;
  setting?: Maybe<SettingType>;
  notificationEmails?: Maybe<NotificationEmailsType>;
  /** List of audit logs with pagination */
  logsPagination?: Maybe<AuditLog>;
};

export type DirectoryQueryUsersArgs = {
  filter?: Maybe<Scalars["String"]>;
};

export type DirectoryQueryUserArgs = {
  userId?: Maybe<Scalars["ID"]>;
};

export type DirectoryQueryUserByEmailArgs = {
  email?: Maybe<Scalars["String"]>;
};

export type DirectoryQueryUserByUuidArgs = {
  uuid?: Maybe<Scalars["String"]>;
};

export type DirectoryQueryUserRolesArgs = {
  userId?: Maybe<Scalars["ID"]>;
};

export type DirectoryQueryUsersPaginationArgs = {
  pageParams?: Maybe<PageParamsInputType>;
};

export type DirectoryQueryOrganizationsArgs = {
  filter?: Maybe<Scalars["String"]>;
};

export type DirectoryQueryOrganizationArgs = {
  organizationId?: Maybe<Scalars["ID"]>;
};

export type DirectoryQueryOrganizationByCvrArgs = {
  cvr?: Maybe<Scalars["String"]>;
};

export type DirectoryQueryApplicationArgs = {
  applicationId?: Maybe<Scalars["ID"]>;
};

export type DirectoryQueryRoleArgs = {
  roleId?: Maybe<Scalars["ID"]>;
};

export type DirectoryQueryRolesForUserArgs = {
  userId?: Maybe<Scalars["ID"]>;
};

export type DirectoryQueryProfilesArgs = {
  applicationId?: Maybe<Scalars["ID"]>;
};

export type DirectoryQueryProfileArgs = {
  profileId?: Maybe<Scalars["ID"]>;
};

export type DirectoryQuerySettingArgs = {
  key?: Maybe<Scalars["String"]>;
};

export type DirectoryQueryNotificationEmailsArgs = {
  filter?: Maybe<Scalars["String"]>;
};

export type DirectoryQueryLogsPaginationArgs = {
  entityId?: Maybe<Scalars["String"]>;
  pageParams?: Maybe<PageParamsInputType>;
};

export type NotificationEmailType = {
  emailId?: Maybe<Scalars["ID"]>;
  emailAddress?: Maybe<Scalars["String"]>;
};

export type NotificationEmailsType = {
  count?: Maybe<Scalars["Int"]>;
  items?: Maybe<Array<Maybe<NotificationEmailType>>>;
};

export type NotificationEmailsTypeItemsArgs = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type OrganizationType = {
  organizationId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  cvr?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  brokerId?: Maybe<Scalars["String"]>;
  cooperationType?: Maybe<Scalars["String"]>;
  nearPension?: Maybe<Scalars["String"]>;
  /** The roles assigned to the organization */
  assigneeRoles?: Maybe<Array<Maybe<RoleAssigneeType>>>;
  /** List of users in the organization */
  users?: Maybe<PaginationResultGraphType_2>;
};

export type OrganizationTypeUsersArgs = {
  pageParams?: Maybe<PageParamsInputType>;
};

export type OrganizationsType = {
  count?: Maybe<Scalars["Int"]>;
  items?: Maybe<Array<Maybe<OrganizationType>>>;
};

export type OrganizationsTypeItemsArgs = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type PageParamsInputType = {
  /** Number of items per page */
  pageSize?: Maybe<Scalars["Int"]>;
  /** Current page number */
  currentPage?: Maybe<Scalars["Int"]>;
};

export type PaginationResultGraphType_2 = {
  /** Items in the current page */
  items?: Maybe<Array<Maybe<UserType>>>;
  /** Total number of items */
  total?: Maybe<Scalars["Int"]>;
};

export type ProfileType = {
  profileId?: Maybe<Scalars["ID"]>;
  applicationId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  /** The application the profile belongs to */
  application?: Maybe<ApplicationType>;
  /** The roles assigned to the profile */
  roles?: Maybe<Array<Maybe<RoleType>>>;
};

export type RemoveProfileRoleInputType = {
  profileId?: Maybe<Scalars["ID"]>;
  roleId?: Maybe<Scalars["ID"]>;
};

export type RemoveRoleApplicationInputType = {
  username?: Maybe<Scalars["String"]>;
};

export type RemoveUserRoleInputType = {
  userId?: Maybe<Scalars["ID"]>;
  roleId?: Maybe<Scalars["ID"]>;
  organizationId?: Maybe<Scalars["ID"]>;
};

export type RoleAssigneeType = {
  roleId?: Maybe<Scalars["ID"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  applicationId?: Maybe<Scalars["ID"]>;
  roleName?: Maybe<Scalars["String"]>;
  organizationName?: Maybe<Scalars["String"]>;
  applicationName?: Maybe<Scalars["String"]>;
};

export type RoleType = {
  roleId?: Maybe<Scalars["ID"]>;
  /** The application the role belongs to */
  application?: Maybe<ApplicationType>;
  name?: Maybe<Scalars["String"]>;
  attribute?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  /** The organizations assigned to the role */
  assignees?: Maybe<Array<Maybe<OrganizationType>>>;
  /** The users assigned to the role */
  users?: Maybe<UsersType>;
};

export type RoleTypeUsersArgs = {
  filter?: Maybe<Scalars["String"]>;
};

export type SettingType = {
  settingId?: Maybe<Scalars["ID"]>;
  key?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type UpdateApplicationInputType = {
  applicationId?: Maybe<Scalars["Guid"]>;
  name?: Maybe<Scalars["String"]>;
};

export type UpdateOrganizationInputType = {
  organizationId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  cvr?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  brokerId?: Maybe<Scalars["String"]>;
  cooperationType?: Maybe<Scalars["String"]>;
  nearPension?: Maybe<Scalars["String"]>;
};

export type UpdateProfileInputType = {
  profileId?: Maybe<Scalars["Guid"]>;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateRoleInputType = {
  roleId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  attribute?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateSettingInputType = {
  settingId?: Maybe<Scalars["Guid"]>;
  key?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type UpdateUserInputType = {
  userId?: Maybe<Scalars["String"]>;
  administrator?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  approvalState?: Maybe<Scalars["Int"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
};

export type User = {
  /** Items in the current page */
  items?: Maybe<Array<Maybe<UserType>>>;
  /** Total number of items */
  total?: Maybe<Scalars["Int"]>;
};

export type UserProfileType = {
  userId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["Guid"]>;
  isSuperAdmin?: Maybe<Scalars["Boolean"]>;
  isAdmin?: Maybe<Scalars["Boolean"]>;
};

export type UserRoleAssignmentType = {
  userId?: Maybe<Scalars["ID"]>;
  roleId?: Maybe<Scalars["ID"]>;
  roleAttribute?: Maybe<Scalars["String"]>;
  roleName?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  organizationName?: Maybe<Scalars["String"]>;
  cvr?: Maybe<Scalars["String"]>;
};

export type UserRoleType = {
  roleId?: Maybe<Scalars["ID"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  applicationId?: Maybe<Scalars["ID"]>;
  roleName?: Maybe<Scalars["String"]>;
  organizationName?: Maybe<Scalars["String"]>;
  applicationName?: Maybe<Scalars["String"]>;
};

export type UserType = {
  userId?: Maybe<Scalars["ID"]>;
  /** The organization the user belongs to */
  organization?: Maybe<OrganizationType>;
  administrator?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  firstname?: Maybe<Scalars["String"]>;
  lastname?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  mitID?: Maybe<Scalars["String"]>;
  enabled?: Maybe<Scalars["Boolean"]>;
  approvalState?: Maybe<Scalars["Int"]>;
  lastLogin?: Maybe<Scalars["DateTime"]>;
  /** The roles assigned to the user */
  roles?: Maybe<Array<Maybe<UserRoleType>>>;
};

export type UsersType = {
  count?: Maybe<Scalars["Int"]>;
  items?: Maybe<Array<Maybe<UserType>>>;
};

export type UsersTypeItemsArgs = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileQuery = {
  userProfile?: Maybe<
    Pick<
      UserProfileType,
      "isAdmin" | "isSuperAdmin" | "name" | "userId" | "organizationId"
    >
  >;
};

export type CreateApplicationMutationVariables = Exact<{
  input: CreateApplicationInputType;
}>;

export type CreateApplicationMutation = {
  createApplication?: Maybe<ApplicationDetailsPartsFragment>;
};

export type DeleteApplicationMutationVariables = Exact<{
  applicationID: Scalars["ID"];
}>;

export type DeleteApplicationMutation = Pick<
  DirectoryMutation,
  "deleteApplication"
>;

export type UpdateApplicationMutationVariables = Exact<{
  input: UpdateApplicationInputType;
}>;

export type UpdateApplicationMutation = {
  updateApplication?: Maybe<ApplicationDetailsPartsFragment>;
};

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type GetUserByEmailQuery = {
  userByEmail?: Maybe<
    Pick<UserType, "userId" | "email" | "firstname" | "lastname"> & {
      organization?: Maybe<Pick<OrganizationType, "name" | "cvr">>;
      roles?: Maybe<
        Array<
          Maybe<
            Pick<
              UserRoleType,
              | "roleId"
              | "organizationId"
              | "applicationId"
              | "roleName"
              | "organizationName"
              | "applicationName"
            >
          >
        >
      >;
    }
  >;
};

export type AssignUserRoleOnBehalfOfMutationVariables = Exact<{
  input: AssignUserRoleOnBehalfOfInputfType;
}>;

export type AssignUserRoleOnBehalfOfMutation = Pick<
  DirectoryMutation,
  "assignUserRoleOnBehalfOf"
>;

export type GetRoleAssigneesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoleAssigneesQuery = {
  roleAssignees?: Maybe<Array<Maybe<RoleAssigneePartsFragment>>>;
};

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInputType;
}>;

export type CreateOrganizationMutation = {
  createOrganization?: Maybe<OrganizationPartsFragment>;
};

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars["ID"];
}>;

export type DeleteOrganizationMutation = Pick<
  DirectoryMutation,
  "deleteOrganization"
>;

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInputType;
}>;

export type UpdateOrganizationMutation = {
  updateOrganization?: Maybe<OrganizationPartsFragment>;
};

export type ResetUserPasswordMutationVariables = Exact<{
  input: ChangeUserPasswordInputType;
}>;

export type ResetUserPasswordMutation = Pick<
  DirectoryMutation,
  "changeUserPassword"
>;

export type ApplicationDetailsPartsFragment = Pick<
  ApplicationType,
  "applicationId" | "name"
>;

export type NotificationEmailPartsFragment = Pick<
  NotificationEmailType,
  "emailId" | "emailAddress"
>;

export type OrganizationPartsFragment = Pick<
  OrganizationType,
  | "name"
  | "organizationId"
  | "cvr"
  | "type"
  | "brokerId"
  | "cooperationType"
  | "externalId"
  | "nearPension"
> & { users?: Maybe<Pick<PaginationResultGraphType_2, "total">> };

export type ProfilePartsFragment = Pick<
  ProfileType,
  "profileId" | "applicationId" | "name" | "description"
>;

export type RoleAssigneePartsFragment = Pick<
  RoleAssigneeType,
  | "roleId"
  | "organizationId"
  | "applicationId"
  | "roleName"
  | "organizationName"
  | "applicationName"
>;

export type RolePartsFragment = Pick<
  RoleType,
  "name" | "roleId" | "description" | "attribute"
>;

export type SettingPartsFragment = Pick<
  SettingType,
  "settingId" | "key" | "value"
>;

export type UserPartsFragment = Pick<
  UserType,
  | "userId"
  | "email"
  | "firstname"
  | "lastname"
  | "administrator"
  | "phone"
  | "mitID"
  | "approvalState"
  | "enabled"
  | "lastLogin"
> & {
  organization?: Maybe<
    Pick<OrganizationType, "organizationId" | "cvr" | "name" | "type">
  >;
};

export type UserRolePartsFragment = Pick<
  UserRoleType,
  | "roleId"
  | "organizationId"
  | "applicationId"
  | "roleName"
  | "organizationName"
  | "applicationName"
>;

export type GetSelectableRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSelectableRolesQuery = {
  roles?: Maybe<
    Array<
      Maybe<
        Pick<RoleType, "roleId" | "attribute" | "name"> & {
          application?: Maybe<Pick<ApplicationType, "applicationId" | "name">>;
        }
      >
    >
  >;
};

export type GetApplicationQueryVariables = Exact<{
  applicationId?: Maybe<Scalars["ID"]>;
}>;

export type GetApplicationQuery = {
  application?: Maybe<
    {
      roles?: Maybe<
        Array<
          Maybe<
            Pick<RoleType, "description" | "roleId" | "name"> & {
              users?: Maybe<Pick<UsersType, "count">>;
            }
          >
        >
      >;
      profiles?: Maybe<
        Array<
          Maybe<
            Pick<ProfileType, "profileId" | "name" | "description"> & {
              roles?: Maybe<Array<Maybe<Pick<RoleType, "roleId" | "name">>>>;
            }
          >
        >
      >;
    } & ApplicationDetailsPartsFragment
  >;
};

export type GetApplicationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetApplicationsQuery = {
  applications?: Maybe<
    Array<
      Maybe<
        Pick<ApplicationType, "applicationId" | "name"> & {
          roles?: Maybe<Array<Maybe<Pick<RoleType, "name">>>>;
        }
      >
    >
  >;
};

export type GetOrganizationQueryVariables = Exact<{
  organizationId?: Maybe<Scalars["ID"]>;
  pageParams: PageParamsInputType;
}>;

export type GetOrganizationQuery = {
  organization?: Maybe<
    Pick<
      OrganizationType,
      | "name"
      | "organizationId"
      | "cvr"
      | "type"
      | "brokerId"
      | "cooperationType"
      | "externalId"
      | "nearPension"
    > & {
      users?: Maybe<
        Pick<PaginationResultGraphType_2, "total"> & {
          items?: Maybe<
            Array<
              Maybe<
                {
                  roles?: Maybe<Array<Maybe<UserRolePartsFragment>>>;
                } & UserPartsFragment
              >
            >
          >;
        }
      >;
      assigneeRoles?: Maybe<
        Array<
          Maybe<
            Pick<
              RoleAssigneeType,
              | "roleId"
              | "roleName"
              | "applicationId"
              | "applicationName"
              | "organizationId"
              | "organizationName"
            >
          >
        >
      >;
    }
  >;
};

export type GetOrganisationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganisationsQuery = {
  organizations?: Maybe<
    Pick<OrganizationsType, "count"> & {
      items?: Maybe<Array<Maybe<OrganizationPartsFragment>>>;
    }
  >;
};

export type GetProfileApplicationQueryVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
}>;

export type GetProfileApplicationQuery = {
  profile?: Maybe<
    {
      application?: Maybe<
        Pick<ApplicationType, "applicationId" | "name"> & {
          roles?: Maybe<Array<Maybe<Pick<RoleType, "roleId" | "name">>>>;
        }
      >;
      roles?: Maybe<Array<Maybe<Pick<RoleType, "roleId" | "name">>>>;
    } & ProfilePartsFragment
  >;
};

export type AddProfileRoleMutationVariables = Exact<{
  input: AddProfileRoleInputType;
}>;

export type AddProfileRoleMutation = Pick<DirectoryMutation, "addProfileRole">;

export type CreateProfileMutationVariables = Exact<{
  input: CreateProfileInputType;
}>;

export type CreateProfileMutation = {
  createProfile?: Maybe<ProfilePartsFragment>;
};

export type DeleteProfileMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteProfileMutation = Pick<DirectoryMutation, "deleteProfile">;

export type RemoveProfileRoleMutationVariables = Exact<{
  input: RemoveProfileRoleInputType;
}>;

export type RemoveProfileRoleMutation = Pick<
  DirectoryMutation,
  "removeProfileRole"
>;

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInputType;
}>;

export type UpdateProfileMutation = {
  updateProfile?: Maybe<ProfilePartsFragment>;
};

export type GetRoleQueryVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
}>;

export type GetRoleQuery = {
  role?: Maybe<
    {
      application?: Maybe<Pick<ApplicationType, "applicationId" | "name">>;
      assignees?: Maybe<
        Array<Maybe<Pick<OrganizationType, "organizationId" | "name">>>
      >;
      users?: Maybe<{
        items?: Maybe<
          Array<
            Maybe<
              Pick<UserType, "email" | "userId" | "firstname" | "lastname"> & {
                organization?: Maybe<Pick<OrganizationType, "name">>;
              }
            >
          >
        >;
      }>;
    } & RolePartsFragment
  >;
};

export type AddRoleAssigneeMutationVariables = Exact<{
  input: AddRoleAssigneeInputType;
}>;

export type AddRoleAssigneeMutation = Pick<
  DirectoryMutation,
  "addRoleAssignee"
>;

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInputType;
}>;

export type CreateRoleMutation = { createRole?: Maybe<RolePartsFragment> };

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteRoleMutation = Pick<DirectoryMutation, "deleteRole">;

export type RemoveRoleAssigneeMutationVariables = Exact<{
  roleId: Scalars["ID"];
  organizationId: Scalars["ID"];
}>;

export type RemoveRoleAssigneeMutation = Pick<
  DirectoryMutation,
  "removeRoleAssignee"
>;

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInputType;
}>;

export type UpdateRoleMutation = { updateRole?: Maybe<RolePartsFragment> };

export type GetNotificationEmailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNotificationEmailsQuery = {
  notificationEmails?: Maybe<
    Pick<NotificationEmailsType, "count"> & {
      items?: Maybe<Array<Maybe<NotificationEmailPartsFragment>>>;
    }
  >;
};

export type AddNotificationEmailMutationVariables = Exact<{
  input: AddNotificationEmailInputType;
}>;

export type AddNotificationEmailMutation = {
  addNotificationEmail?: Maybe<NotificationEmailPartsFragment>;
};

export type DeleteNotificationEmailMutationVariables = Exact<{
  emailId: Scalars["ID"];
}>;

export type DeleteNotificationEmailMutation = Pick<
  DirectoryMutation,
  "deleteNotificationEmail"
>;

export type GetSettingByKeyQueryVariables = Exact<{
  key: Scalars["String"];
}>;

export type GetSettingByKeyQuery = { setting?: Maybe<SettingPartsFragment> };

export type UpdateSettingMutationVariables = Exact<{
  input: UpdateSettingInputType;
}>;

export type UpdateSettingMutation = {
  updateSetting?: Maybe<SettingPartsFragment>;
};

export type GetUserQueryVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
}>;

export type GetUserQuery = {
  user?: Maybe<
    { roles?: Maybe<Array<Maybe<UserRolePartsFragment>>> } & UserPartsFragment
  >;
};

export type GetRolesForUserQueryVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
}>;

export type GetRolesForUserQuery = {
  rolesForUser?: Maybe<Array<Maybe<RoleAssigneePartsFragment>>>;
};

export type AddUserRoleMutationVariables = Exact<{
  input: AddUserRoleInputType;
}>;

export type AddUserRoleMutation = Pick<DirectoryMutation, "addUserRole">;

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type DeleteUserMutation = Pick<DirectoryMutation, "deleteUser">;

export type RejectUserMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RejectUserMutation = Pick<DirectoryMutation, "rejectUser">;

export type RemoveUserRoleMutationVariables = Exact<{
  input: RemoveUserRoleInputType;
}>;

export type RemoveUserRoleMutation = Pick<DirectoryMutation, "removeUserRole">;

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInputType;
}>;

export type UpdateUserMutation = {
  updateUser?: Maybe<
    {
      roles?: Maybe<
        Array<
          Maybe<
            Pick<
              UserRoleType,
              | "roleId"
              | "organizationId"
              | "applicationId"
              | "roleName"
              | "organizationName"
              | "applicationName"
            >
          >
        >
      >;
    } & UserPartsFragment
  >;
};

export type GetUsersPaginationQueryVariables = Exact<{
  pageParams: PageParamsInputType;
}>;

export type GetUsersPaginationQuery = {
  usersPagination?: Maybe<
    Pick<User, "total"> & {
      items?: Maybe<
        Array<
          Maybe<
            Pick<
              UserType,
              | "userId"
              | "firstname"
              | "lastname"
              | "email"
              | "mitID"
              | "administrator"
              | "approvalState"
              | "enabled"
              | "lastLogin"
            > & {
              organization?: Maybe<Pick<OrganizationType, "organizationId">>;
            }
          >
        >
      >;
    }
  >;
};

export const ApplicationDetailsPartsFragmentDoc = gql`
  fragment ApplicationDetailsParts on ApplicationType {
    applicationId
    name
  }
`;
export const NotificationEmailPartsFragmentDoc = gql`
  fragment NotificationEmailParts on NotificationEmailType {
    emailId
    emailAddress
  }
`;
export const OrganizationPartsFragmentDoc = gql`
  fragment OrganizationParts on OrganizationType {
    name
    organizationId
    cvr
    type
    brokerId
    cooperationType
    externalId
    nearPension
    users {
      total
    }
  }
`;
export const ProfilePartsFragmentDoc = gql`
  fragment ProfileParts on ProfileType {
    profileId
    applicationId
    name
    description
  }
`;
export const RoleAssigneePartsFragmentDoc = gql`
  fragment RoleAssigneeParts on RoleAssigneeType {
    roleId
    organizationId
    applicationId
    roleName
    organizationName
    applicationName
  }
`;
export const RolePartsFragmentDoc = gql`
  fragment RoleParts on RoleType {
    name
    roleId
    description
    attribute
  }
`;
export const SettingPartsFragmentDoc = gql`
  fragment SettingParts on SettingType {
    settingId
    key
    value
  }
`;
export const UserPartsFragmentDoc = gql`
  fragment UserParts on UserType {
    userId
    email
    firstname
    lastname
    administrator
    phone
    organization {
      organizationId
      cvr
      name
      type
    }
    mitID
    approvalState
    enabled
    lastLogin
  }
`;
export const UserRolePartsFragmentDoc = gql`
  fragment UserRoleParts on UserRoleType {
    roleId
    organizationId
    applicationId
    roleName
    organizationName
    applicationName
  }
`;
export const GetProfileDocument = gql`
  query GetProfile {
    userProfile {
      isAdmin
      isSuperAdmin
      name
      userId
      organizationId
    }
  }
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a Vue component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetProfileQuery();
 */
export function useGetProfileQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetProfileQuery,
        GetProfileQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetProfileQuery,
          GetProfileQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetProfileQuery,
          GetProfileQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetProfileQuery,
    GetProfileQueryVariables
  >(GetProfileDocument, {}, options);
}
export type GetProfileQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<GetProfileQuery, GetProfileQueryVariables>;
export const CreateApplicationDocument = gql`
  mutation CreateApplication($input: CreateApplicationInputType!) {
    createApplication(input: $input) {
      ...ApplicationDetailsParts
    }
  }
  ${ApplicationDetailsPartsFragmentDoc}
`;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateApplicationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateApplicationMutation,
        CreateApplicationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateApplicationMutation,
          CreateApplicationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >(CreateApplicationDocument, options);
}
export type CreateApplicationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >;
export const DeleteApplicationDocument = gql`
  mutation DeleteApplication($applicationID: ID!) {
    deleteApplication(applicationId: $applicationID)
  }
`;

/**
 * __useDeleteApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteApplicationMutation({
 *   variables: {
 *     applicationID: // value for 'applicationID'
 *   },
 * });
 */
export function useDeleteApplicationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteApplicationMutation,
        DeleteApplicationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteApplicationMutation,
          DeleteApplicationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteApplicationMutation,
    DeleteApplicationMutationVariables
  >(DeleteApplicationDocument, options);
}
export type DeleteApplicationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteApplicationMutation,
    DeleteApplicationMutationVariables
  >;
export const UpdateApplicationDocument = gql`
  mutation UpdateApplication($input: UpdateApplicationInputType!) {
    updateApplication(input: $input) {
      ...ApplicationDetailsParts
    }
  }
  ${ApplicationDetailsPartsFragmentDoc}
`;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateApplicationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateApplicationMutation,
        UpdateApplicationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateApplicationMutation,
          UpdateApplicationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >(UpdateApplicationDocument, options);
}
export type UpdateApplicationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >;
export const GetUserByEmailDocument = gql`
  query GetUserByEmail($email: String!) {
    userByEmail(email: $email) {
      userId
      email
      firstname
      lastname
      organization {
        name
        cvr
      }
      roles {
        roleId
        organizationId
        applicationId
        roleName
        organizationName
        applicationName
      }
    }
  }
`;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a Vue component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserByEmailQuery({
 *   email: // value for 'email'
 * });
 */
export function useGetUserByEmailQuery(
  variables:
    | GetUserByEmailQueryVariables
    | VueCompositionApi.Ref<GetUserByEmailQueryVariables>
    | ReactiveFunction<GetUserByEmailQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUserByEmailQuery,
        GetUserByEmailQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUserByEmailQuery,
          GetUserByEmailQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUserByEmailQuery,
          GetUserByEmailQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >(GetUserByEmailDocument, variables, options);
}
export type GetUserByEmailQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetUserByEmailQuery,
    GetUserByEmailQueryVariables
  >;
export const AssignUserRoleOnBehalfOfDocument = gql`
  mutation AssignUserRoleOnBehalfOf(
    $input: AssignUserRoleOnBehalfOfInputfType!
  ) {
    assignUserRoleOnBehalfOf(input: $input)
  }
`;

/**
 * __useAssignUserRoleOnBehalfOfMutation__
 *
 * To run a mutation, you first call `useAssignUserRoleOnBehalfOfMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserRoleOnBehalfOfMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAssignUserRoleOnBehalfOfMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAssignUserRoleOnBehalfOfMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AssignUserRoleOnBehalfOfMutation,
        AssignUserRoleOnBehalfOfMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AssignUserRoleOnBehalfOfMutation,
          AssignUserRoleOnBehalfOfMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AssignUserRoleOnBehalfOfMutation,
    AssignUserRoleOnBehalfOfMutationVariables
  >(AssignUserRoleOnBehalfOfDocument, options);
}
export type AssignUserRoleOnBehalfOfMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AssignUserRoleOnBehalfOfMutation,
    AssignUserRoleOnBehalfOfMutationVariables
  >;
export const GetRoleAssigneesDocument = gql`
  query getRoleAssignees {
    roleAssignees {
      ...RoleAssigneeParts
    }
  }
  ${RoleAssigneePartsFragmentDoc}
`;

/**
 * __useGetRoleAssigneesQuery__
 *
 * To run a query within a Vue component, call `useGetRoleAssigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleAssigneesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRoleAssigneesQuery();
 */
export function useGetRoleAssigneesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetRoleAssigneesQuery,
        GetRoleAssigneesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetRoleAssigneesQuery,
          GetRoleAssigneesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetRoleAssigneesQuery,
          GetRoleAssigneesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetRoleAssigneesQuery,
    GetRoleAssigneesQueryVariables
  >(GetRoleAssigneesDocument, {}, options);
}
export type GetRoleAssigneesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetRoleAssigneesQuery,
    GetRoleAssigneesQueryVariables
  >;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($input: CreateOrganizationInputType!) {
    createOrganization(input: $input) {
      ...OrganizationParts
    }
  }
  ${OrganizationPartsFragmentDoc}
`;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOrganizationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateOrganizationMutation,
        CreateOrganizationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateOrganizationMutation,
          CreateOrganizationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >;
export const DeleteOrganizationDocument = gql`
  mutation DeleteOrganization($organizationId: ID!) {
    deleteOrganization(organizationId: $organizationId)
  }
`;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOrganizationMutation({
 *   variables: {
 *     organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteOrganizationMutation,
        DeleteOrganizationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteOrganizationMutation,
          DeleteOrganizationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInputType!) {
    updateOrganization(input: $input) {
      ...OrganizationParts
    }
  }
  ${OrganizationPartsFragmentDoc}
`;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOrganizationMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateOrganizationMutation,
        UpdateOrganizationMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateOrganizationMutation,
          UpdateOrganizationMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >;
export const ResetUserPasswordDocument = gql`
  mutation ResetUserPassword($input: ChangeUserPasswordInputType!) {
    changeUserPassword(input: $input)
  }
`;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResetUserPasswordMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        ResetUserPasswordMutation,
        ResetUserPasswordMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          ResetUserPasswordMutation,
          ResetUserPasswordMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >(ResetUserPasswordDocument, options);
}
export type ResetUserPasswordMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >;
export const GetSelectableRolesDocument = gql`
  query GetSelectableRoles {
    roles {
      roleId
      attribute
      name
      application {
        applicationId
        name
      }
    }
  }
`;

/**
 * __useGetSelectableRolesQuery__
 *
 * To run a query within a Vue component, call `useGetSelectableRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectableRolesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSelectableRolesQuery();
 */
export function useGetSelectableRolesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetSelectableRolesQuery,
        GetSelectableRolesQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetSelectableRolesQuery,
          GetSelectableRolesQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetSelectableRolesQuery,
          GetSelectableRolesQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetSelectableRolesQuery,
    GetSelectableRolesQueryVariables
  >(GetSelectableRolesDocument, {}, options);
}
export type GetSelectableRolesQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetSelectableRolesQuery,
    GetSelectableRolesQueryVariables
  >;
export const GetApplicationDocument = gql`
  query getApplication($applicationId: ID) {
    application(applicationId: $applicationId) {
      ...ApplicationDetailsParts
      roles {
        description
        roleId
        name
        users {
          count
        }
      }
      profiles {
        profileId
        name
        description
        roles {
          roleId
          name
        }
      }
    }
  }
  ${ApplicationDetailsPartsFragmentDoc}
`;

/**
 * __useGetApplicationQuery__
 *
 * To run a query within a Vue component, call `useGetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetApplicationQuery({
 *   applicationId: // value for 'applicationId'
 * });
 */
export function useGetApplicationQuery(
  variables:
    | GetApplicationQueryVariables
    | VueCompositionApi.Ref<GetApplicationQueryVariables>
    | ReactiveFunction<GetApplicationQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        GetApplicationQuery,
        GetApplicationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetApplicationQuery,
          GetApplicationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetApplicationQuery,
          GetApplicationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >(GetApplicationDocument, variables, options);
}
export type GetApplicationQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >;
export const GetApplicationsDocument = gql`
  query getApplications {
    applications {
      applicationId
      name
      roles {
        name
      }
    }
  }
`;

/**
 * __useGetApplicationsQuery__
 *
 * To run a query within a Vue component, call `useGetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetApplicationsQuery();
 */
export function useGetApplicationsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetApplicationsQuery,
        GetApplicationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetApplicationsQuery,
          GetApplicationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetApplicationsQuery,
          GetApplicationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetApplicationsQuery,
    GetApplicationsQueryVariables
  >(GetApplicationsDocument, {}, options);
}
export type GetApplicationsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetApplicationsQuery,
    GetApplicationsQueryVariables
  >;
export const GetOrganizationDocument = gql`
  query GetOrganization(
    $organizationId: ID
    $pageParams: PageParamsInputType!
  ) {
    organization(organizationId: $organizationId) {
      name
      organizationId
      cvr
      type
      brokerId
      cooperationType
      externalId
      nearPension
      users(pageParams: $pageParams) {
        total
        items {
          roles {
            ...UserRoleParts
          }
          ...UserParts
        }
      }
      assigneeRoles {
        roleId
        roleName
        applicationId
        applicationName
        applicationName
        organizationId
        organizationName
      }
    }
  }
  ${UserRolePartsFragmentDoc}
  ${UserPartsFragmentDoc}
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a Vue component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOrganizationQuery({
 *   organizationId: // value for 'organizationId'
 *   pageParams: // value for 'pageParams'
 * });
 */
export function useGetOrganizationQuery(
  variables:
    | GetOrganizationQueryVariables
    | VueCompositionApi.Ref<GetOrganizationQueryVariables>
    | ReactiveFunction<GetOrganizationQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetOrganizationQuery,
        GetOrganizationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationQuery,
          GetOrganizationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetOrganizationQuery,
          GetOrganizationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GetOrganizationDocument, variables, options);
}
export type GetOrganizationQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >;
export const GetOrganisationsDocument = gql`
  query getOrganisations {
    organizations {
      count
      items {
        ...OrganizationParts
      }
    }
  }
  ${OrganizationPartsFragmentDoc}
`;

/**
 * __useGetOrganisationsQuery__
 *
 * To run a query within a Vue component, call `useGetOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetOrganisationsQuery();
 */
export function useGetOrganisationsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetOrganisationsQuery,
        GetOrganisationsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetOrganisationsQuery,
          GetOrganisationsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetOrganisationsQuery,
          GetOrganisationsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetOrganisationsQuery,
    GetOrganisationsQueryVariables
  >(GetOrganisationsDocument, {}, options);
}
export type GetOrganisationsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetOrganisationsQuery,
    GetOrganisationsQueryVariables
  >;
export const GetProfileApplicationDocument = gql`
  query getProfileApplication($id: ID) {
    profile(profileId: $id) {
      ...ProfileParts
      application {
        applicationId
        name
        roles {
          roleId
          name
        }
      }
      roles {
        roleId
        name
      }
    }
  }
  ${ProfilePartsFragmentDoc}
`;

/**
 * __useGetProfileApplicationQuery__
 *
 * To run a query within a Vue component, call `useGetProfileApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileApplicationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetProfileApplicationQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetProfileApplicationQuery(
  variables:
    | GetProfileApplicationQueryVariables
    | VueCompositionApi.Ref<GetProfileApplicationQueryVariables>
    | ReactiveFunction<GetProfileApplicationQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        GetProfileApplicationQuery,
        GetProfileApplicationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetProfileApplicationQuery,
          GetProfileApplicationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetProfileApplicationQuery,
          GetProfileApplicationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetProfileApplicationQuery,
    GetProfileApplicationQueryVariables
  >(GetProfileApplicationDocument, variables, options);
}
export type GetProfileApplicationQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetProfileApplicationQuery,
    GetProfileApplicationQueryVariables
  >;
export const AddProfileRoleDocument = gql`
  mutation addProfileRole($input: AddProfileRoleInputType!) {
    addProfileRole(input: $input)
  }
`;

/**
 * __useAddProfileRoleMutation__
 *
 * To run a mutation, you first call `useAddProfileRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddProfileRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddProfileRoleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAddProfileRoleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddProfileRoleMutation,
        AddProfileRoleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddProfileRoleMutation,
          AddProfileRoleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddProfileRoleMutation,
    AddProfileRoleMutationVariables
  >(AddProfileRoleDocument, options);
}
export type AddProfileRoleMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddProfileRoleMutation,
    AddProfileRoleMutationVariables
  >;
export const CreateProfileDocument = gql`
  mutation createProfile($input: CreateProfileInputType!) {
    createProfile(input: $input) {
      ...ProfileParts
    }
  }
  ${ProfilePartsFragmentDoc}
`;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateProfileMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfileMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateProfileMutation,
        CreateProfileMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateProfileMutation,
          CreateProfileMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateProfileMutation,
    CreateProfileMutationVariables
  >(CreateProfileDocument, options);
}
export type CreateProfileMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateProfileMutation,
    CreateProfileMutationVariables
  >;
export const DeleteProfileDocument = gql`
  mutation deleteProfile($id: ID!) {
    deleteProfile(profileId: $id)
  }
`;

/**
 * __useDeleteProfileMutation__
 *
 * To run a mutation, you first call `useDeleteProfileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteProfileMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProfileMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteProfileMutation,
        DeleteProfileMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteProfileMutation,
          DeleteProfileMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteProfileMutation,
    DeleteProfileMutationVariables
  >(DeleteProfileDocument, options);
}
export type DeleteProfileMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteProfileMutation,
    DeleteProfileMutationVariables
  >;
export const RemoveProfileRoleDocument = gql`
  mutation removeProfileRole($input: RemoveProfileRoleInputType!) {
    removeProfileRole(input: $input)
  }
`;

/**
 * __useRemoveProfileRoleMutation__
 *
 * To run a mutation, you first call `useRemoveProfileRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProfileRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveProfileRoleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveProfileRoleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveProfileRoleMutation,
        RemoveProfileRoleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveProfileRoleMutation,
          RemoveProfileRoleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveProfileRoleMutation,
    RemoveProfileRoleMutationVariables
  >(RemoveProfileRoleDocument, options);
}
export type RemoveProfileRoleMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveProfileRoleMutation,
    RemoveProfileRoleMutationVariables
  >;
export const UpdateProfileDocument = gql`
  mutation updateProfile($input: UpdateProfileInputType!) {
    updateProfile(input: $input) {
      ...ProfileParts
    }
  }
  ${ProfilePartsFragmentDoc}
`;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateProfileMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateProfileMutation,
        UpdateProfileMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateProfileMutation,
          UpdateProfileMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options);
}
export type UpdateProfileMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >;
export const GetRoleDocument = gql`
  query getRole($id: ID) {
    role(roleId: $id) {
      ...RoleParts
      application {
        applicationId
        name
      }
      assignees {
        organizationId
        name
      }
      users {
        items {
          organization {
            name
          }
          email
          userId
          firstname
          lastname
        }
      }
    }
  }
  ${RolePartsFragmentDoc}
`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a Vue component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRoleQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetRoleQuery(
  variables:
    | GetRoleQueryVariables
    | VueCompositionApi.Ref<GetRoleQueryVariables>
    | ReactiveFunction<GetRoleQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<GetRoleQuery, GetRoleQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<GetRoleQuery, GetRoleQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<GetRoleQuery, GetRoleQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<GetRoleQuery, GetRoleQueryVariables>(
    GetRoleDocument,
    variables,
    options
  );
}
export type GetRoleQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<GetRoleQuery, GetRoleQueryVariables>;
export const AddRoleAssigneeDocument = gql`
  mutation AddRoleAssignee($input: AddRoleAssigneeInputType!) {
    addRoleAssignee(input: $input)
  }
`;

/**
 * __useAddRoleAssigneeMutation__
 *
 * To run a mutation, you first call `useAddRoleAssigneeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleAssigneeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddRoleAssigneeMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAddRoleAssigneeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddRoleAssigneeMutation,
        AddRoleAssigneeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddRoleAssigneeMutation,
          AddRoleAssigneeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddRoleAssigneeMutation,
    AddRoleAssigneeMutationVariables
  >(AddRoleAssigneeDocument, options);
}
export type AddRoleAssigneeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddRoleAssigneeMutation,
    AddRoleAssigneeMutationVariables
  >;
export const CreateRoleDocument = gql`
  mutation CreateRole($input: CreateRoleInputType!) {
    createRole(input: $input) {
      ...RoleParts
    }
  }
  ${RolePartsFragmentDoc}
`;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateRoleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        CreateRoleMutation,
        CreateRoleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          CreateRoleMutation,
          CreateRoleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >(CreateRoleDocument, options);
}
export type CreateRoleMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >;
export const DeleteRoleDocument = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(roleId: $id)
  }
`;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteRoleMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteRoleMutation,
        DeleteRoleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteRoleMutation,
          DeleteRoleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
  >(DeleteRoleDocument, options);
}
export type DeleteRoleMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
  >;
export const RemoveRoleAssigneeDocument = gql`
  mutation RemoveRoleAssignee($roleId: ID!, $organizationId: ID!) {
    removeRoleAssignee(roleId: $roleId, organizationId: $organizationId)
  }
`;

/**
 * __useRemoveRoleAssigneeMutation__
 *
 * To run a mutation, you first call `useRemoveRoleAssigneeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleAssigneeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveRoleAssigneeMutation({
 *   variables: {
 *     roleId: // value for 'roleId'
 *     organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRemoveRoleAssigneeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveRoleAssigneeMutation,
        RemoveRoleAssigneeMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveRoleAssigneeMutation,
          RemoveRoleAssigneeMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveRoleAssigneeMutation,
    RemoveRoleAssigneeMutationVariables
  >(RemoveRoleAssigneeDocument, options);
}
export type RemoveRoleAssigneeMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveRoleAssigneeMutation,
    RemoveRoleAssigneeMutationVariables
  >;
export const UpdateRoleDocument = gql`
  mutation UpdateRole($input: UpdateRoleInputType!) {
    updateRole(input: $input) {
      ...RoleParts
    }
  }
  ${RolePartsFragmentDoc}
`;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateRoleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateRoleMutation,
        UpdateRoleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateRoleMutation,
          UpdateRoleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >(UpdateRoleDocument, options);
}
export type UpdateRoleMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >;
export const GetNotificationEmailsDocument = gql`
  query GetNotificationEmails {
    notificationEmails {
      count
      items {
        ...NotificationEmailParts
      }
    }
  }
  ${NotificationEmailPartsFragmentDoc}
`;

/**
 * __useGetNotificationEmailsQuery__
 *
 * To run a query within a Vue component, call `useGetNotificationEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationEmailsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetNotificationEmailsQuery();
 */
export function useGetNotificationEmailsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<
        GetNotificationEmailsQuery,
        GetNotificationEmailsQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetNotificationEmailsQuery,
          GetNotificationEmailsQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetNotificationEmailsQuery,
          GetNotificationEmailsQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetNotificationEmailsQuery,
    GetNotificationEmailsQueryVariables
  >(GetNotificationEmailsDocument, {}, options);
}
export type GetNotificationEmailsQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetNotificationEmailsQuery,
    GetNotificationEmailsQueryVariables
  >;
export const AddNotificationEmailDocument = gql`
  mutation AddNotificationEmail($input: AddNotificationEmailInputType!) {
    addNotificationEmail(input: $input) {
      ...NotificationEmailParts
    }
  }
  ${NotificationEmailPartsFragmentDoc}
`;

/**
 * __useAddNotificationEmailMutation__
 *
 * To run a mutation, you first call `useAddNotificationEmailMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddNotificationEmailMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddNotificationEmailMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAddNotificationEmailMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddNotificationEmailMutation,
        AddNotificationEmailMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddNotificationEmailMutation,
          AddNotificationEmailMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddNotificationEmailMutation,
    AddNotificationEmailMutationVariables
  >(AddNotificationEmailDocument, options);
}
export type AddNotificationEmailMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddNotificationEmailMutation,
    AddNotificationEmailMutationVariables
  >;
export const DeleteNotificationEmailDocument = gql`
  mutation DeleteNotificationEmail($emailId: ID!) {
    deleteNotificationEmail(emailId: $emailId)
  }
`;

/**
 * __useDeleteNotificationEmailMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationEmailMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationEmailMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteNotificationEmailMutation({
 *   variables: {
 *     emailId: // value for 'emailId'
 *   },
 * });
 */
export function useDeleteNotificationEmailMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteNotificationEmailMutation,
        DeleteNotificationEmailMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteNotificationEmailMutation,
          DeleteNotificationEmailMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteNotificationEmailMutation,
    DeleteNotificationEmailMutationVariables
  >(DeleteNotificationEmailDocument, options);
}
export type DeleteNotificationEmailMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteNotificationEmailMutation,
    DeleteNotificationEmailMutationVariables
  >;
export const GetSettingByKeyDocument = gql`
  query GetSettingByKey($key: String!) {
    setting(key: $key) {
      ...SettingParts
    }
  }
  ${SettingPartsFragmentDoc}
`;

/**
 * __useGetSettingByKeyQuery__
 *
 * To run a query within a Vue component, call `useGetSettingByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingByKeyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSettingByKeyQuery({
 *   key: // value for 'key'
 * });
 */
export function useGetSettingByKeyQuery(
  variables:
    | GetSettingByKeyQueryVariables
    | VueCompositionApi.Ref<GetSettingByKeyQueryVariables>
    | ReactiveFunction<GetSettingByKeyQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetSettingByKeyQuery,
        GetSettingByKeyQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetSettingByKeyQuery,
          GetSettingByKeyQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetSettingByKeyQuery,
          GetSettingByKeyQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetSettingByKeyQuery,
    GetSettingByKeyQueryVariables
  >(GetSettingByKeyDocument, variables, options);
}
export type GetSettingByKeyQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetSettingByKeyQuery,
    GetSettingByKeyQueryVariables
  >;
export const UpdateSettingDocument = gql`
  mutation UpdateSetting($input: UpdateSettingInputType!) {
    updateSetting(input: $input) {
      ...SettingParts
    }
  }
  ${SettingPartsFragmentDoc}
`;

/**
 * __useUpdateSettingMutation__
 *
 * To run a mutation, you first call `useUpdateSettingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSettingMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSettingMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateSettingMutation,
        UpdateSettingMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateSettingMutation,
          UpdateSettingMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateSettingMutation,
    UpdateSettingMutationVariables
  >(UpdateSettingDocument, options);
}
export type UpdateSettingMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateSettingMutation,
    UpdateSettingMutationVariables
  >;
export const GetUserDocument = gql`
  query getUser($id: ID) {
    user(userId: $id) {
      ...UserParts
      roles {
        ...UserRoleParts
      }
    }
  }
  ${UserPartsFragmentDoc}
  ${UserRolePartsFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a Vue component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetUserQuery(
  variables:
    | GetUserQueryVariables
    | VueCompositionApi.Ref<GetUserQueryVariables>
    | ReactiveFunction<GetUserQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<GetUserQuery, GetUserQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<GetUserQuery, GetUserQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<GetUserQuery, GetUserQueryVariables>
      > = {}
) {
  return VueApolloComposable.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    variables,
    options
  );
}
export type GetUserQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<GetUserQuery, GetUserQueryVariables>;
export const GetRolesForUserDocument = gql`
  query getRolesForUser($id: ID) {
    rolesForUser(userId: $id) {
      ...RoleAssigneeParts
    }
  }
  ${RoleAssigneePartsFragmentDoc}
`;

/**
 * __useGetRolesForUserQuery__
 *
 * To run a query within a Vue component, call `useGetRolesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesForUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRolesForUserQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetRolesForUserQuery(
  variables:
    | GetRolesForUserQueryVariables
    | VueCompositionApi.Ref<GetRolesForUserQueryVariables>
    | ReactiveFunction<GetRolesForUserQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<
        GetRolesForUserQuery,
        GetRolesForUserQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetRolesForUserQuery,
          GetRolesForUserQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetRolesForUserQuery,
          GetRolesForUserQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetRolesForUserQuery,
    GetRolesForUserQueryVariables
  >(GetRolesForUserDocument, variables, options);
}
export type GetRolesForUserQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetRolesForUserQuery,
    GetRolesForUserQueryVariables
  >;
export const AddUserRoleDocument = gql`
  mutation AddUserRole($input: AddUserRoleInputType!) {
    addUserRole(input: $input)
  }
`;

/**
 * __useAddUserRoleMutation__
 *
 * To run a mutation, you first call `useAddUserRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddUserRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddUserRoleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAddUserRoleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        AddUserRoleMutation,
        AddUserRoleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          AddUserRoleMutation,
          AddUserRoleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    AddUserRoleMutation,
    AddUserRoleMutationVariables
  >(AddUserRoleDocument, options);
}
export type AddUserRoleMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    AddUserRoleMutation,
    AddUserRoleMutationVariables
  >;
export const DeleteUserDocument = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteUserMutation({
 *   variables: {
 *     userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        DeleteUserMutation,
        DeleteUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          DeleteUserMutation,
          DeleteUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DeleteUserDocument, options);
}
export type DeleteUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >;
export const RejectUserDocument = gql`
  mutation rejectUser($id: ID!) {
    rejectUser(userId: $id)
  }
`;

/**
 * __useRejectUserMutation__
 *
 * To run a mutation, you first call `useRejectUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRejectUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRejectUserMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRejectUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RejectUserMutation,
        RejectUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RejectUserMutation,
          RejectUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RejectUserMutation,
    RejectUserMutationVariables
  >(RejectUserDocument, options);
}
export type RejectUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RejectUserMutation,
    RejectUserMutationVariables
  >;
export const RemoveUserRoleDocument = gql`
  mutation RemoveUserRole($input: RemoveUserRoleInputType!) {
    removeUserRole(input: $input)
  }
`;

/**
 * __useRemoveUserRoleMutation__
 *
 * To run a mutation, you first call `useRemoveUserRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveUserRoleMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserRoleMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        RemoveUserRoleMutation,
        RemoveUserRoleMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          RemoveUserRoleMutation,
          RemoveUserRoleMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    RemoveUserRoleMutation,
    RemoveUserRoleMutationVariables
  >(RemoveUserRoleDocument, options);
}
export type RemoveUserRoleMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    RemoveUserRoleMutation,
    RemoveUserRoleMutationVariables
  >;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInputType!) {
    updateUser(input: $input) {
      ...UserParts
      roles {
        roleId
        organizationId
        applicationId
        roleName
        organizationName
        applicationName
      }
    }
  }
  ${UserPartsFragmentDoc}
`;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateUserMutation,
        UpdateUserMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateUserMutation,
          UpdateUserMutationVariables
        >
      >
) {
  return VueApolloComposable.useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument, options);
}
export type UpdateUserMutationCompositionFunctionResult =
  VueApolloComposable.UseMutationReturn<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >;
export const GetUsersPaginationDocument = gql`
  query GetUsersPagination($pageParams: PageParamsInputType!) {
    usersPagination(pageParams: $pageParams) {
      items {
        userId
        firstname
        lastname
        email
        mitID
        administrator
        approvalState
        enabled
        lastLogin
        organization {
          organizationId
        }
      }
      total
    }
  }
`;

/**
 * __useGetUsersPaginationQuery__
 *
 * To run a query within a Vue component, call `useGetUsersPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersPaginationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUsersPaginationQuery({
 *   pageParams: // value for 'pageParams'
 * });
 */
export function useGetUsersPaginationQuery(
  variables:
    | GetUsersPaginationQueryVariables
    | VueCompositionApi.Ref<GetUsersPaginationQueryVariables>
    | ReactiveFunction<GetUsersPaginationQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<
        GetUsersPaginationQuery,
        GetUsersPaginationQueryVariables
      >
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<
          GetUsersPaginationQuery,
          GetUsersPaginationQueryVariables
        >
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<
          GetUsersPaginationQuery,
          GetUsersPaginationQueryVariables
        >
      > = {}
) {
  return VueApolloComposable.useQuery<
    GetUsersPaginationQuery,
    GetUsersPaginationQueryVariables
  >(GetUsersPaginationDocument, variables, options);
}
export type GetUsersPaginationQueryCompositionFunctionResult =
  VueApolloComposable.UseQueryReturn<
    GetUsersPaginationQuery,
    GetUsersPaginationQueryVariables
  >;
