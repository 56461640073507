// CONFIG
export const CONFIG_URI = "/config";
export const OIDC_LOGOUT_URI = "/loggedout";

// OIDC
export const OIDC_CALLBACK_URI = "/callback";

export const OIDC_SILENT_RENEW_URI = "/oidc-silent-renew";
export const OIDC_SCOPE = "openid stanlab_read stanlab_write";
export const OIDC_RESPONSE_TYPE = "code";
export const OIDC_EXPIRING_NOTIF_TIME = 120;

export const TOAST_DURATION = 7000;

export const OrganizationTypes = {
  Mægler: "Mægler",
  Partner: "Partner",
  TiedAgent: "Tied Agents",
};

export const ORGANIZATION_TYPES = [
  {
    label: "Mægler",
    value: OrganizationTypes.Mægler,
  },
  {
    label: "Partner",
    value: OrganizationTypes.Partner,
  },
  {
    label: "Tied Agents",
    value: OrganizationTypes.TiedAgent,
  },
];
